console.log('JS OK 1');

/////////////////////////////
//READ MORE - single page

var btnMore = document.querySelector(".btnMore > p:last-of-type");
var moreText = document.querySelector(".moreText");

if (btnMore){

  //Ouvrir more text
  btnMore.addEventListener('click', function() {
    console.log('click more');
    moreText.classList.toggle("ouvert");
    btnMore.classList.toggle("ouvert");
  });

}

/////////////////////////////
// MENU MOBILE

var btnMobile = document.querySelector("#btnMenu");
var iconeMobile = document.querySelector("#nav-toggle");
var navMobile = document.querySelector("#nav");

if (btnMobile){

  //Ouvrir more text
  btnMobile.addEventListener('click', function() {
    console.log('click menu');
    iconeMobile.classList.toggle("active");
    navMobile.classList.toggle("open");
  });

}

/////////////////////////////
// SCROLLBAR
// Initialize the plugin
var scrollContainer = document.querySelector('.swipeProjet');
if (scrollContainer){
	var ps = new PerfectScrollbar(scrollContainer);
}


// UNIQUEMENT SUR les liste projets
var grid = document.querySelector('#listProjet');

if ( grid ) {

/////////////////////////////
// MASONRY
// https://masonry.desandro.com
//-------------------------------------//
var msnry = new Masonry( grid, {
  itemSelector: 'none', // select none at first
  columnWidth: '.grid-sizer',
  gutter: '.gutter-sizer',
  percentPosition: true,
  stagger: 30,
  // nicer reveal transition
  visibleStyle: { transform: 'translateY(0)', opacity: 1 },
  hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
});


// initial items reveal
imagesLoaded( grid, function() {
  grid.classList.remove('are-images-unloaded');
  msnry.options.itemSelector = '.blocMiniature';
  var items = grid.querySelectorAll('.blocMiniature');
  msnry.appended( items );
});

//-------------------------------------//
// init Infinte Scroll PROJET
var infScroll = new InfiniteScroll( grid, {
  path: '.nextPageLink a',
  append: '.blocMiniature',
  outlayer: msnry,
  status: '.page-load-status',
  history: false,
  hideNav: '.nextPrev'
});

} //FIN IF


//-------------------------------------//
// init Infinte Scroll PROJET
var pageActu = document.querySelector('.listeActu');

if ( pageActu ) {

var infScroll = new InfiniteScroll( pageActu, {
  path: '.nextPageLink a',
  append: '.blocMini-actu',
  outlayer: msnry,
  status: '.page-load-status',
  history: false,
  hideNav: '.nextPrev'
});

}